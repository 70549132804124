import React from "react";

function HeaderComponent({ title }) {
  return (
    <div className="header-component">
      <h2>{title}</h2>
    </div>
  );
}

export default HeaderComponent;
