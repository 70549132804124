import React, { useState } from "react";
import "./MainDoctor.scss";
import Datatable from "../../../components/datatable/Datatable";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import HeaderPage from "../../../components/HeaderPage/HeaderPage";
import Navbar from "../../../components/Navbar/Navbar";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ModalMulti from "../../../components/ModalMulti/ModalMulti";
import ModalDelete from "../../../components/ModalDelete/ModalDelete";

function MainDoctor() {
  const [t, i18n] = useTranslation();
  const label = { inputProps: { "aria-label": "Size switch demo" } };

  let navigation = useNavigate();
  let gotonewfn = (id, name) => {
    if (name === "add") {
      navigation("/doctor/add");
    }
    if (name === "edit") {
      navigation(`/doctor/edit/${id}`);
    }
    if (name === "show") {
      navigation(`/doctor/${id}`);
    }
  };

  const userColumns = [
    { field: "id", headerName: "ID", width: 60, sortable: "desc" },
    {
      field: "first_name",
      headerName: "Patient",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            {params.row.user_picture ? (
              <img
                className="cellImg"
                src={`${"BASE_URL"}/storage/${params.row.user_picture}`}
                alt="avatar"
              />
            ) : (
              <div className="boxImageChar">
                <span> {params.row.first_name.slice(0, 1)}</span>
              </div>
            )}

            {params.row.first_name + " " + params.row.last_name}
          </div>
        );
      },
    },
    {
      field: "gender",
      headerName: "Gender",
      sortable: false,
      width: 100,
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      sortable: false,
      width: 160,
    },
    {
      field: "location",
      headerName: "Address",
      sortable: false,
      width: 130,
    },
    {
      field: "location_details",
      headerName: "Address Two",
      sortable: false,
      width: 200,
    },
    {
      field: "birthday",
      headerName: "Date Of Birth",
      sortable: false,
      width: 150,
      renderCell: (params) => {
        return (
          <div className="remaining">{params.row.birthday.split(" ")[0]}</div>
        );
      },
    },
    {
      field: "Action",
      headerName: "Action",
      sortable: false,
      width: 120,
      renderCell: (params) => {
        return (
          <div className="action-icon">
            <div className=" list-icons ">
              <div className="icon-view">
                <ModalMulti params={params} filter="doctors" type="viwe" />
              </div>
              <div className="icon-edit">
                <EditIcon
                  onClick={(e) => gotonewfn(params.id, "edit")}
                  className="icon-edit"
                />
              </div>
              <div className="icon-delete">
                <ModalDelete filter="doctors" params={params} />
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  const [selectedRowIds, setSelectedRowIds] = useState([]);
  // Function to handle selection change
  const handleSelectionChange = (selectionModel) => {
    // Store the selected row IDs in state
    setSelectedRowIds(selectionModel);
    // Log the selected row IDs to the console
    console.log("Selected Row IDs:", selectionModel);
  };

  // let dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(getAllCompany({ lang: i18n.language }));
  //   dispatch(resetsingleDataComapny(""));
  // }, []);

  // let {
  //   data: companies,
  //   loading,
  //   errorAll,
  // } = useSelector((state) => state.companies);

  let errorAll = false;
  let loading = false;
  let doctors = [
    {
      id: 1,
      first_name: "John",
      last_name: "Doe",
      gender: "Male",
      phone_number: "1234567890",
      location: "New York",
      location_details: "Advertisement",
      birthday: "1990-01-01",
      user_picture: "", // You may adjust this path as per your file structure
    },
    {
      id: 2,
      first_name: "Jane",
      last_name: "Doe",
      gender: "Female",
      phone_number: "9876543210",
      location: "Los Angeles",
      location_details: "Friend",
      birthday: "1995-05-05",
      user_picture: "", // You may adjust this path as per your file structure
    },
  ];

  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <div className="two-box">
        <HeaderPage
          data={doctors}
          selectedRowIds={selectedRowIds}
          title="Doctors"
          filter="Doctors"
          link="/doctor/add"
        />
        <div className="table">
          {errorAll ? (
            <ErrorCompo />
          ) : loading ? (
            <div className="loading">
              <CircularIndeterminate />
            </div>
          ) : (
            <Datatable
              userColumns={userColumns}
              userRows={doctors && doctors}
              onSelectionModelChange={handleSelectionChange}
            />
          )}
        </div>
      </div>
    </div>
  );
}
export default MainDoctor;
